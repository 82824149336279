import cx from 'classnames';
import Link from 'components/Link';
import { LinkProps as NextLinkProps } from 'next/link';
import React, { ReactNode } from 'react';
import styles from './Button.module.css';


type ButtonSize = 'small' | 'large';

export interface ButtonProps {
  children?: ReactNode;
  className?: string;
  color?: 'primary' | 'secondary' | 'tertiary' | 'dark' | 'primary-inverse' | 'secondary-inverse' | 'dark-hollow';
  component?: 'button' | 'link';
  disabled?: boolean;
  link?: NextLinkProps;
  onClick?: () => void;
  size?: ButtonSize;
  style?: 'rounded' | 'squared' | 'narrow';
  title?: string;
  type?: 'button' | 'submit' | 'reset';
}

export function Button({
  children,
  className,
  color = 'primary',
  component = 'button',
  disabled,
  link,
  onClick,
  size = 'small',
  style = 'squared',
  title,
  type = 'button',
}: ButtonProps) {
  const Component = component === 'link' ? Link : 'button';
  const classNames = cx(
    styles['Button'],
    styles[size],
    className,
    styles[color],
    styles[style || 'rounded'],
    disabled ? styles['disabled'] : '',
  );

  return (
    //@ts-ignore
    <Component className={classNames} disabled={disabled} title={title} type={type} onClick={onClick} {...link}>
      {children}
    </Component>
  );
}
