import { useEffect } from 'react';

export const useOutsideClick = (ref, cb) => {
    useEffect(() => {
        
        function handleClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                cb()
            }
        }

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick)
        };

    }, [ref]);
};