import cx from 'classnames';
import Button from 'components/Button';
import Link from 'components/Link';
import SiteMenu from 'components/SiteMenu';
import { useHomeRoute } from 'lib/hooks/useHomeRoute';
import React, { useState } from 'react';
import { Link as ButtonLink } from '../Link/Link';
import SideDrawer from '../SideDrawer/SideDrawer';
import styles from './Header.module.css';

export interface HeaderProps {
  showMenu?: boolean;
}

const PublicMobileMenu = () => {
  return (
    <>
      <ButtonLink className="m-b h-color--navy-200 " href="/about">
        ABOUT
      </ButtonLink>
      <ButtonLink className="m-b h-color--navy-200 " href="/contact">
        CONTACT
      </ButtonLink>

      <Button className="m-b m-t" color="secondary" component="link" style="squared" link={{ href: '/register' }}>
        SIGN UP
      </Button>

      <Button component="link" style="squared" link={{ href: '/login' }}>
        LOGIN
      </Button>
    </>
  );
};

export function Header({ showMenu }: HeaderProps) {
  const homeRoute = useHomeRoute();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const onDrawerClose = () => setIsDrawerOpen(false);

  return (
    <header className={cx('no-print', styles['Header'])}>
      <div className={styles['Header--Container']}>
        <div className={styles['Header--Logo']}>
          <Link href={homeRoute}>
            <img src="/images/logo.svg" /> 
          </Link>
          <Link href={homeRoute}>
            <span className="header-text">HOME</span>
          </Link>
        </div>
        <div className={styles['Header--help']}>
          <Link href="/get-help">
            <img src="/images/icon-help.svg" />
          </Link>
        </div>

        <div className="l-flex align-center m-l--auto">
          {showMenu && (
            <div className="desktop-only">
              <SiteMenu isDesktop />
            </div>
          )}

          {!showMenu && (
            <div className="desktop-only">
              <ButtonLink className="m-x--large h-color--navy-200 " href="/about">
                ABOUT
              </ButtonLink>
              <ButtonLink className="m-x--large h-color--navy-200 " href="/contact">
                CONTACT
              </ButtonLink>

              <Button className="m-x" color="secondary" component="link" style="squared" link={{ href: '/register' }}>
                SIGN UP
              </Button>

              <Button component="link" style="squared" link={{ href: '/login' }}>
                LOGIN
              </Button>
            </div>
          )}

          <div className="mobile-only">
            <SideDrawer isOpen={isDrawerOpen} onClose={onDrawerClose}>
              {showMenu ? <SiteMenu /> : <PublicMobileMenu />}
            </SideDrawer>

            <button className="l-flex" onClick={handleDrawer}>
              <img src="/images/icon-menu.svg" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
