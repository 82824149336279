import React from 'react';
import Link from 'components/Link';
import cx from 'classnames';

import styles from './Footer.module.css';

export function Footer() {
  return (
    <footer className={cx('no-print', styles['Footer'])}>
      <div className={styles['Footer--content']}>
        <p className="m-r m-b">© {new Date().getFullYear()} Crystal Clear | </p>
        <Link isExternal className="l-flex-one m-b" href="https://netfront.com.au/">
          Site by Netfront
        </Link>
        {/* <Link href="/contact">Contact</Link> */}
        <div className="l-flex m-b">
          <Link className="m-r" href="/terms-of-use">
            Terms
          </Link>
          <Link href="/privacy">Privacy</Link>
        </div>
      </div>
    </footer>
  );
}
