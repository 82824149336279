import { _ClearTokens, _GetAccessToken } from '@netfront/gelada-react-shared';
import { getSignedUrl } from 'application/middleware/ekardo/queries/AWS/getSignedUrl';
import cx from 'classnames';
import gtag from 'ga-gtag';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Link } from '../Link/Link';
import styles from './SiteMenu.module.css';

interface SiteMenuProps {
  isDesktop?: boolean;
}

export function SiteMenu({ isDesktop }: SiteMenuProps) {
  const router = useRouter();

  const [isPrintingPdf, setIsPrintingPdf] = useState(false);
  const handleLogOut = () => {
    _ClearTokens();
    localStorage.removeItem("AccessToken");
    gtag('set', { user_id: '' });
    router.push('/');
  };

  const handlePrint = () => {
    setIsPrintingPdf(true);
    const currentUrl = window.location.href;
    const url = currentUrl.charAt(currentUrl.length - 1) === '/' ? window.location.href.slice(0, -1) : currentUrl;
    fetch(`https://vxrppzxiv1.execute-api.ap-southeast-2.amazonaws.com/Prod/api/Pdf/generateFromUrl`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: _GetAccessToken(),
      },
      body: JSON.stringify({ url, projectId: process.env.REACT_APP_PROJECT_ID }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const url = getSignedUrl(data.assetId, data.contentType);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.setAttribute('download', document.title);
        a.click();
      })
      .catch((e) => console.error(e))
      .finally(() => setIsPrintingPdf(false));
  };

  return (
    <nav>
      {isDesktop ? (
        <ul className={cx('l-flex', styles['SiteMenu__ul'])}>
          <li className={styles['SiteMenu__li']}>
            <div className={styles['SiteMenu__print-icon-container']} onClick={handlePrint}>
              {isPrintingPdf && <span className={styles['Spinner']} />}
              {!isPrintingPdf && <img className={styles['SiteMenu__img']} alt="Print page" src="/images/icon-print.svg" />}
            </div>
          </li>
          <li className={styles['SiteMenu__li']}>
            <Link className={styles['SiteMenu__a']} href="/dashboard">
              COURSE
            </Link>
          </li>
          <li className={styles['SiteMenu__li']}>
            <Link className={styles['SiteMenu__a']} href="/profile">
              PROFILE
            </Link>
          </li>
          <li className={styles['SiteMenu__li']}>
            <button className={styles['SiteMenu__a']} onClick={handleLogOut}>
              LOGOUT
            </button>
          </li>
        </ul>
      ) : (
        <ul>
          <li className="m-b--med h-color--navy-200">
            <div className={styles['SiteMenu__print-icon-container-mobile']} onClick={handlePrint}>
              {isPrintingPdf && (
                <p>
                  <span className="m-r--xxsmall weight-400">VIEW PDF</span>
                  <span className={cx(styles['Spinner'], styles['SiteMenu_spinner-mobile-background'])} />
                </p>
              )}
              {!isPrintingPdf && (
                <p>
                  <span className="m-r--xxsmall weight-400">VIEW PDF</span>
                  <img className={styles['SiteMenu__img-mobile']} alt="Print page" src="/images/icon-print.svg" />
                </p>
              )}
            </div>
          </li>
          <li className="m-b--large">
            <Link className="h-color--navy-200" href="/dashboard">
              COURSE
            </Link>
          </li>
          <li className="m-b--large">
            <Link className="h-color--navy-200" href="/profile">
              PROFILE
            </Link>
          </li>
          <li className="m-b--large">
            <button className="h-color--navy-200" onClick={handleLogOut}>
              LOGOUT
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
}
