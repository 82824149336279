import s3 from './s3';
import { expiryTime } from 'consts/s3';

// note: this provides a one time use url. For user clickable urls, use useGetSignedUrl
export const getSignedUrl = (key: string, contentType: string) => {
  if (!key) return;
  return s3.getSignedUrl('getObject', {
    // eslint-disable-next-line no-undef
    Bucket: process.env.REACT_APP_BUCKET,
    Key: key,
    Expires: expiryTime,
    ResponseContentType: contentType,
  });
};
